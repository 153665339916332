<template>
  <div class="container">
    <div class="wrap">
      <img src="@/assets/image/bg@2x.png" class="bg" />
      <div class="scan_code_wrap">
        <div class="title">欢迎登录拍照云</div>
        <div class="tips">微信扫一扫识别登录</div>
        <div class="qrcode" id="wx-login-qrcode">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created(){
  },
  mounted(){
    this.get_wx_qrcode()
  },
  methods: {
    get_wx_qrcode(){
        //从微信的开发文档不难看出回调域名需要经过encodeURIComponent处理
        var obj = new WxLogin({
            self_redirect: true,  //值为true时，直接在二维码的弹窗中进行页面跳转及其余操作,
            id: "wx-login-qrcode",       //需要承载二维码的容器id  
            appid: "wx84d6624f41f58915",
            scope: "snsapi_login",     //网页授权，目前网页只有这一种方式，静默授权
            redirect_uri: encodeURIComponent(window.location.origin+'/loginSuccess'),     //回调域名(地址前部分是项目域名，后部分loginSuccess是微信需要跳转的路径（vue中的路由名称）)
            state: Math.random(),
            style: "black", //  black  white
            href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7DQogICAgd2lkdGg6IDEwMCU7DQp9DQouaW1wb3dlckJveCAudGl0bGV7DQogICAgZGlzcGxheTogbm9uZTsNCn0=",// 
        });
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  position: relative;
  .wrap {
    width: 14rem;
    height: 100%;
    position: relative;
    .bg {
      width: 100%;
      height: 100%;
    }

    .scan_code_wrap {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 5.14rem;
      height: 6.16rem;
      border-radius: 0.08rem;
      background-color: #fff;
      box-shadow: 0px 0.12rem 0.48rem 0.01rem rgba(147, 149, 153, 0.16);
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        color: #000000;
        font-size: 0.32rem;
        margin-top: 0.6rem;
      }

      .tips {
        color: #606466;
        font-size: 0.16rem;
        margin: 0.24rem 0 0.4rem;
      }
      // .qrcode{
      //   width: 3rem;
      //   height: 4rem;
      //   border-radius: 0.04rem;
      // }

      img {
        width: 3rem;
        height: 3rem;
        border-radius: 0.04rem;
      }
    }
  }
}

iframe{
  width: 200px!important;
  height: 250px!important;
}
</style>
